<template>
    <div class="text-center">
        <template v-if="checkPermission('UserGroupResource.PUT.UserGroup')">
        <button
            @click="editUserGroup()"
            class="btn btn-sm btn-clean btn-icon btn-icon-md"
            title="Edit"
        >
            <i class="la la-pencil-square"></i>
        </button>
        </template>
        <template v-if="checkPermission('UserGroupResource.DELETE.UserGroup.id')">
        <button
            @click="deleteUserGroup()"
            class="btn btn-sm btn-clean btn-icon btn-icon-md"
            title="Hapus"
        >
            <i class="la la-trash"></i>
        </button>
        </template>
    </div>
</template>

<script>
import CheckPermission from "../../../services/checkPermission.service";
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
import SweetAlertTemplate from "./../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
const blockUI = new BlockUI();
const UserGroupRepository = RepositoryFactory.get("userGroup");
const checkPermission = new CheckPermission();
const SweetAlert = Vue.extend(SweetAlertTemplate);
export default {
    props: {
        userGroupVue: { type: Object },
        rowData: { type: Object }
    },
    methods: {
        deleteUserGroup() {
            var vx = this;
            let instance = new SweetAlert().showConfirm({
                swalTitle: "Apakah Anda yakin?",
                swalText: "Data User Group akan terhapus.",
                swalType: "warning",
                onConfirmButton: function () {
                    blockUI.blockPage();
                    vx.delete();
                },
                onCancelButton: function () {
                    blockUI.unblockPage();
                }
            });
        },
        async delete() {
            var vx = this;

            await UserGroupRepository.deleteUserGroup(this.rowData.id)
                .then(response => {
                    new Promise((resolve, reject) => {
                        vx.userGroupVue.fetch(resolve);
                    }).then((response) => {
                        let instance = new SweetAlert().showInfo({
                        swalTitle: "Berhasil!",
                        swalText: "Data User Group berhasil terhapus.",
                        swalType: "success",
                        onConfirmButton: function () {
                            blockUI.unblockPage();
                        }
                        });
                    });
                })
                .catch(error => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Gagal!",
                        swalText: "Data User Group gagal terhapus.",
                        swalType: "error",
                        onConfirmButton: function () {
                            blockUI.unblockPage();
                        }
                    });
                });
            },
        editUserGroup() {
            blockUI.blockPage();
            UserGroupRepository.getUserGroup(this.rowData.id).then(response=>{
                blockUI.unblockPage();
                this.userGroupVue.dataEdit = response.data;
                this.userGroupVue.showModal();
            }).catch(error => {
                blockUI.unblockPage();
                var msg = (error.message) ? error.message : error;
                this.showToastError('User Groups Id : ' + msg);
            })

        },
        showToastError: function(message) {
            toastr.error(message);
        },
        checkPermission: function (method) {
            return checkPermission.isCanAccess(method);
        }
    }
}
</script>
